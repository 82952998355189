import React, { Component } from 'react';
import Facebook from "./Facebook";
import GoogleAnalytics from "./GoogleAnalytics";

import { SettingsContext } from "../Magazine/MagazineSettings";

export class ServiceCookiesComponent extends Component {

    render() {
        return(
            <SettingsContext.Consumer>
                {({ audience, logo }) => {

                    return (
                        <Facebook>
                            <GoogleAnalytics audience={ audience }>
                                {this.props.children}
                            </GoogleAnalytics>
                        </Facebook>
                    )
                }}
            </SettingsContext.Consumer>
        );
    }

}
