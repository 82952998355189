import 'core-js/stable';
import 'react-app-polyfill/ie9';

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import {  Provider } from "react-redux";
import typeDefs from "./schema";
import createStore from "./storeNew";

import * as serviceWorker from './serviceWorker';

import { ApolloProvider } from "react-apollo";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { persistCache } from 'apollo-cache-persist';
import { HttpLink } from "apollo-link-http";
import { withClientState } from 'apollo-link-state';
import { ApolloLink } from 'apollo-link';
import GlobalComponent from "./modules/GlobalComponent";
import * as Loadable from "react-loadable";

import persist from "./persist";
import { ConnectedRouter } from "connected-react-router";
import {gql} from "apollo-client-preset";
import {GET_ALL_ARTICLES, GET_ALL_ARTICLES_WITH_CONTENT, GET_ARTICLE_READ} from "./Queries";
import { PersistGate } from 'redux-persist/integration/react'
import {Router} from "react-router-dom";
import {unregister} from "./serviceWorker";

const defaults = {
    articles: [],
    numeros: [],
    // ssr value, wildcard
    visibilityFilter: 'SHOW_ALL',
    currentNumero: {
        __typename: "currentNumero"
    },
    NumeroSwitch: {
        __typename: "NumeroSwitch",
    },
    menu: false,
    getMagazine: {
        __typename: "getMagazine"
    }
};

let uri = 'http://localhost:3000/graphql';

class RootCache extends Component {

    state = {
        client: null,
        loaded: false,
    };

    async componentDidMount() {
        const cache = new InMemoryCache().restore(window.__APOLLO_STATE__);

        console.log("window.__APOLLO_URI__", window.__APOLLO_URI__);

        uri = (window.__APOLLO_URI__) ? window.__APOLLO_URI__ : uri;
        let resetPersist = (window.__RESET_PERSIST__) ? window.__RESET_PERSIST__ : false;

        const stateLink = withClientState({
            cache,
            defaults,
            typeDefs,
            // resolvers: {},
            resolvers: {
                // Article: {
                //     isRead: (article, args, ctx) => {
                //         return false
                //     }
                // },
                Query: {
                    getReadArticle: (_root, readArticle, { cache, getCacheKey }) => {
                        const id = `ArticleRead:${ readArticle.id }`;
                        const fragment = gql`
                            fragment readed on ArticleRead {
                                readed
                            }
                        `;
                        return cache.readFragment({ fragment, id });
                    },
                    getArticles: (_root, __, { cache, getCacheKey }) => {

                        const data = cache.data.data;

                        return Object.keys(data).filter(key => {
                            return (data[key].__typename === 'ArticleDetails') && !key.includes("getArticles")
                        }).map((article) => {

                            let category = Object.keys(data).filter(key => {
                                return (data[key].__typename === 'Category') && key.includes(data[article].category.id)
                            });

                            let articleObj = {...data[article]};
                            articleObj.category = data[category];

                            return articleObj;
                        });

                    }
                },
                Mutation: {
                    addReadArticle: (_root, readArticle, { cache, getCacheKey }) => {

                        const id = `ArticleRead:${ readArticle.id }`;
                        const fragment = gql`
                            fragment readArticle on ArticleRead {
                                readed
                            }
                        `;
                        const todo = cache.readFragment({ fragment, id });
                        const data = { ...todo, readed: true, __typename: "ArticleRead" };

                        cache.writeFragment({ fragment, id, data });
                        return null;

                    },
                    setReadArticle: (_root, variables, { cache, getCacheKey }) => {
                        const id = getCacheKey({ __typename: 'Article', id: variables.id });

                        const fragment = gql`
                            fragment isReaded on Article {
                                isRead
                                slug
                            }
                        `;

                        const article = cache.readFragment({ fragment, id });
                        const data = { ...article, isRead: true };

                        // cache.writeData({ id, data });
                        cache.writeFragment({ fragment, id, data });
                        return null;
                    },
                },
            }
        });

        const client = new ApolloClient({
            cache,
            link: ApolloLink.from([stateLink, new HttpLink({
                uri
            })]),
            //ssrForceFetchDelay : 100
            ssrForceFetchDelay: 0,
        });

        if(window.localStorage.length > 0){
            persist.init(new InMemoryCache(), window.localStorage, true);
        } else {
            persist.init(cache, window.localStorage, true);
        }

        if(!resetPersist){

            try {
                await persist.restore();
            } catch (error) {
                console.error('Error restoring Apollo cache', error);
            }

        } else {

            try {

                await persist.purge();
                unregister();

                try {
                    await persist.persist();
                } catch (error) {
                    console.error('Error restoring Apollo cache', error);
                }


            } catch (error) {
                console.error('Error purge Apollo cache', error);
            }

        }

        this.setState({
            client,
            loaded: true,
        });
    }

    render() {
        const { client, loaded } = this.state;
        //const store = configureStore();

        const { store, history, persistor } = createStore();

        if (!loaded) {
            return null;
        }

        return (
            <ApolloProvider client={ client }>
                <Provider store={ store }>
                    <GlobalComponent>
                        <PersistGate loading={ null } persistor={ persistor }>
                            <ConnectedRouter history={ history }>
                                <App />
                            </ConnectedRouter>
                        </PersistGate>
                    </GlobalComponent>
                </Provider>
            </ApolloProvider>
        );
    }
}

const rootElement = document.getElementById('root');


if (rootElement.hasChildNodes()) {

    window.onload = () => {

        setTimeout(function() {
            Loadable.preloadReady().then(() => {
                ReactDOM.hydrate(<RootCache />, rootElement);
            });
        }, 0)

    };

} else {
    window.onload = () => {
        Loadable.preloadReady().then(() => {
            ReactDOM.render(<RootCache />, rootElement);
        });
    };
}

serviceWorker.register();
