import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { GET_ARTICLE } from "../../Queries";
import {connect} from "react-redux";

let currentId = 0;

class Prefetch extends Component {

    constructor(props){
        super(props);
    }

    isPreview(){
        let parameters = window.location.search.substr(1);
        return parameters.includes("preview=true");
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.changeNumero) {
            this.getAllArticlesNumeros();
            this.props.changeNumeroOff();
        }
    }

    componentDidMount() {
        if(typeof window !== "undefined"){
            if(!this.isPreview()) {
                this.getAllArticlesNumeros();
            }
        }
    }

    getAllArticlesNumeros() { let that = this;
        let numeros = this.props.getAll.numeros;

        if(this.props.magazine){

            setTimeout(function(){
                numeros.forEach((numero) => {
                    let fetch = that.fetchAsyncCallback(numero.articles);
                });

            }, 4000);
        }

    }

    async fetchAsyncCallback(articles) { let that = this;

        if(articles[currentId]){
            let article = await this.prefetch(articles[currentId]);
            let image = await this.prefetchWallpaper(articles[currentId]);
            currentId++;

            return that.fetchAsyncCallback(articles);

        }

    }

    async prefetchWallpaper($article) {

        if($article) {
            if ($article.wallpaper.url) {
                fetch($article.wallpaper.url).then((res) => {
                    return true;
                })
            } else {
                return true;
            }
        }
    }

    async prefetch($article) { let that = this;

        setTimeout(function(){
            let article = that.props.client.query({ query: GET_ARTICLE, ssr: false, variables: {
                    magazine_slug : that.props.magazine.slug,
                    article_id: $article._id
                }});

            if(article) return true;

        }, 1000);
    }

    render() {
        return null;
    }


}

const mapStateToProps = (state, props) => {
    return {
        changeNumero: state.numeros.changeNumero
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeNumeroOff: () => {
            dispatch({
                type: 'CHANGE_NUMERO_OFF'
            });
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(Prefetch)));
