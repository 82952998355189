import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { ScrollProvider } from "../Scroll/Scroll";

export const SettingsContext = React.createContext('font');

const Loading = () => {
    return null
};

let internalFonts =
    [
        'Avalon', 'AvalonBook', 'CircularStd',
        'CircularStdBook', 'Gotham', 'GothamBlack', 'GothamBook', 'VonnesBold', 'Flyweight', 'Marianina',
        'GalanoGrotesque', 'BebasNeue', 'Hermes', 'HelveticaNeue', 'NeoSans', 'AvenirNextLTPro', 'Flama',
        'SportingGrotesque', 'Lato', 'Muli', 'Work Sans', 'ArcherPro', 'Playfair Display'
    ];

export const fontsObjects = {
    Avalon: Loadable({
        loader: () => import("../Fonts/Avalon"),
        loading: Loading,
        modules: [ "Avalon" ]
    }),
    AvalonBook: Loadable({
        loader: () => import("../Fonts/AvalonBook"),
        loading: Loading,
        modules: [ "AvalonBook" ]
    }),
    GalanoGrotesque: Loadable({
        loader: () => import("../Fonts/GalanoGrotesque"),
        loading: Loading,
        modules: [ "GalanoGrotesque" ]
    }),
    BebasNeue: Loadable({
        loader: () => import("../Fonts/BebasNeue"),
        loading: Loading,
        modules: [ "BebasNeue" ]
    }),
    CircularStd: Loadable({
        loader: () => import("../Fonts/CircularStd"),
        loading: Loading,
        modules: [ "CircularStd" ]
    }),
    CircularStdBook: Loadable({
        loader: () => import("../Fonts/CircularStdBook"),
        loading: Loading,
        modules: [ "CircularStdBook" ]
    }),
    GothamBlack: Loadable({
        loader: () => import("../Fonts/GothamBlack"),
        loading: Loading,
        modules: [ "GothamBlack" ]
    }),
    Gotham: Loadable({
        loader: () => import("../Fonts/Gotham"),
        loading: Loading,
        modules: [ "Gotham" ]
    }),
    GothamBook: Loadable({
        loader: () => import("../Fonts/GothamBook"),
        loading: Loading,
        modules: [ "GothamBook" ]
    }),
    VonnesBold: Loadable({
        loader: () => import("../Fonts/VonnesBold"),
        loading: Loading,
        modules: [ "VonnesBold" ]
    }),
    Flyweight: Loadable({
        loader: () => import("../Fonts/Flyweight"),
        loading: Loading,
        modules: [ "Flyweight" ]
    }),
    Marianina: Loadable({
        loader: () => import("../Fonts/Marianina"),
        loading: Loading,
        modules: [ "Marianina" ]
    }),
    Hermes: Loadable({
        loader: () => import("../Fonts/Hermes"),
        loading: Loading,
        modules: [ "Hermes" ]
    }),
    HelveticaNeue: Loadable({
        loader: () => import("../Fonts/HelveticaNeue"),
        loading: Loading,
        modules: [ "HelveticaNeue" ]
    }),
    NeoSans: Loadable({
        loader: () => import("../Fonts/NeoSans"),
        loading: Loading,
        modules: [ "NeoSans" ]
    }),
    Flama: Loadable({
        loader: () => import("../Fonts/Flama"),
        loading: Loading,
        modules: [ "Flama" ]
    }),
    AvenirNextLTPro: Loadable({
        loader: () => import("../Fonts/AvenirNextLTPro"),
        loading: Loading,
        modules: [ "AvenirNextLTPro" ]
    }),
    SportingGrotesque: Loadable({
        loader: () => import("../Fonts/SportingGrotesque"),
        loading: Loading,
        modules: [ "SportingGrotesque" ]
    }),
    Lato: Loadable({
        loader: () => import("../Fonts/Lato"),
        loading: Loading,
        modules: [ "Lato" ]
    }),
    Muli: Loadable({
        loader: () => import("../Fonts/Muli"),
        loading: Loading,
        modules: [ "Muli" ]
    }),
    'Work Sans': Loadable({
        loader: () => import("../Fonts/WorkSans"),
        loading: Loading,
        modules: [ "Work Sans" ]
    }),
    ArcherPro: Loadable({
        loader: () => import("../Fonts/ArcherPro"),
        loading: Loading,
        modules: [ "ArcherPro" ]
    }),
    'Playfair Display': Loadable({
        loader: () => import("../Fonts/PlayfairDisplay"),
        loading: Loading,
        modules: [ "Playfair Display" ]
    }),
};


class FontsComponent extends Component {

    loadFonts() {
        const { fonts } = this.props;

        if(fonts.length > 0) {
            return fonts.map((font, index) => {
                if (internalFonts.indexOf(font) > -1) {
                    const TagName = fontsObjects[ font ];
                    return <TagName key={ index } />
                }
                return null;
            });
        }

        return null;
    }

    render() {
        return(
            <div>{ this.loadFonts() }</div>
        )
    }

}

export class MagazineSettings extends Component {

    constructor(props) {
        super(props);

        const { fonts, couleur, logo, theme, audience, contact, custom, socials, indexed, kiosque } = this.props.settings;

        this.state = {
            fontsCollection: [ fonts.family1, fonts.family2, fonts.family3, fonts.family4 ],
            fonts: this.getFonts([ fonts.family1, fonts.family2, fonts.family3, fonts.family4 ]),
            couleur: couleur,
            logo: logo,
            contact,
            socials,
            custom: custom,
            theme,
            audience,
            indexed,
            kiosque
        };

    }

    isFontInternal(font) {
        return internalFonts.indexOf(font) > -1;
    }

    componentDidMount() {
        this.mountWebfonts();
    }

    mountWebfonts() { let that = this;

        let fontsArr = [];

        this.state.fontsCollection.forEach((font) => {
            if (!that.isFontInternal(font)) {
                fontsArr.push(font + ':400,400i,700,700i,800,900');
            }
        });

        if(fontsArr.length > 0) {
            import('webfontloader').then(WebFontLoader => {
                WebFontLoader.load({
                    classes: false,
                    google: {
                        families: [...fontsArr]
                    },
                    active: function() {
                        setTimeout(function() {
                            document.getElementsByTagName('html')[0].className = "wf-active";
                        }, 100);
                    }
                });
            });
        } else {
            setTimeout(function() {
                document.getElementsByTagName('html')[0].className = "wf-active";
            }, 100);
        }

    }

    getFonts(fonts) {
        return {
            family1: {
                fontFamily: fonts[0]
            },
            family2: {
                fontFamily: fonts[1],
            },
            family3: {
                fontFamily: fonts[2],
            },
            family4: {
                fontFamily: fonts[3],
            }
        };
    }

    render() {

        let fontsArr = [];

        this.state.fontsCollection.forEach((font) => {
            if(this.isFontInternal(font)){
                fontsArr.push(font);
            }
        });

        return(
            <SettingsContext.Provider value={ this.state }>
                <ScrollProvider>
                    <FontsComponent fonts={ fontsArr }/>
                    { this.props.children }
                </ScrollProvider>
            </SettingsContext.Provider>
        )
    }




}
