import React, { Component } from 'react';
import { connect } from "react-redux";
import { ServiceCookie } from "./ServiceCookie";

class Facebook extends Component {

    constructor(props){
        super(props);

        this.state = {
            rgpd: null,
            change: false,
            init: true
        };

        this.serviceCookie = new ServiceCookie();

    }

    componentDidMount() {
        if(typeof window !== "undefined"){
            this.initFacebook();
        }
        
        this.setState({
            rgpd: this.props.rgpd
        })
    }

    activateFacebook() {

        if(this.serviceCookie.set('facebook')){
        } else {
            console.log("Cookies non activés");
        }

    }

    disableFacebook() {
        if(this.serviceCookie.disable('facebook')){
            this.serviceCookie.remove("", "/", ".facebook.com");
        } else {
            console.log("Cookies non activés");
        }
    }

    initCookie() {

        if(this.state.init){
            if (this.serviceCookie.isActivated('facebook') === false) {
                this.disableFacebook();
                this.props.disableFacebookService();
                this.props.alreadyInteractRGPD();

            } else if(this.serviceCookie.isActivated('facebook') === true){
                this.activateFacebook();
                this.props.activateFacebookService();
                this.props.alreadyInteractRGPD();

            }
            this.setState({
                init: false
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {

        let cookie = new ServiceCookie();

        if(prevState.rgpd){

            let prev = cookie.findServiceAcceptation(prevState.rgpd, 'socials', 'facebook');
            let next = cookie.findServiceAcceptation(this.props.rgpd, 'socials', 'facebook');

            if(next !== prev){

                let rgpd = JSON.parse(JSON.stringify( this.props.rgpd ));

                this.setState({
                    rgpd: rgpd,
                    change: true
                });

            } else {
                if(this.state.change){
                    this.setState({
                        change: false
                    });
                }
            }

        }

    }

    initFacebook() {

        if(this.state.rgpd && this.state.change) {

            if (this.serviceCookie.isAccepted(this.state.rgpd, 'socials', 'facebook')) {
                this.activateFacebook();
            } else {
                this.disableFacebook();
            }

            this.props.alreadyInteractRGPD();

        }

        if(this.state.rgpd) {
            this.initCookie();
        }

    }

    render(){
        return this.props.children;
    }

}

const mapStateToProps = (state, props) => {
    return {
        rgpd: state.rgpd
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        activateFacebookService: () => {
            return dispatch({
                type: 'UNBLOCK_SERVICE',
                category: 'socials',
                service: 'facebook'
            })
        },
        disableFacebookService: () => {
            return dispatch({
                type: 'BLOCK_SERVICE',
                category: 'socials',
                service: 'facebook'
            })
        },
        alreadyInteractRGPD: () => {
            return dispatch({
                type: 'ALREADY_INTERACT'
            })
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Facebook);
