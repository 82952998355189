import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { Manifest } from "../Manifest/Manifest";
import { SettingsContext } from "../Magazine/MagazineSettings";

function truncate(string, length){
    if (string.length > length)
        return string.substring(0 , length) +'...';
    else
        return string;
}

class MetasWebsite extends Component {

    constructor(props){
        super(props);
        this.magazine = props.magazine;

        this.state = {
            meta_description: "",
            meta_descrition_fb: "",
            meta_image_fb: "",
            meta_title_fb: "",
            meta_title: "",
            meta_description_twitter: "",
            meta_image_twitter: "",
            meta_title_twitter: "",
            indexed: 1
        };

        this.constructMetas();

    }

    constructMetas() {

        this.state.meta_description = this.magazine.metas.meta_description;

        if(this.magazine.metas.meta_descrition_fb !== "") {
            this.state.meta_description_fb = this.magazine.metas.meta_descrition_fb;
        } else {
            this.state.meta_description_fb = this.magazine.metas.meta_description;
        }

        if(this.magazine.metas.meta_image_fb !== "") {
            this.state.meta_image_fb = this.magazine.metas.meta_image_fb
        } else {
            this.state.meta_image_fb = this.magazine.wallpaper;
        }

        if(this.magazine.metas.meta_title_fb !== "") {
            this.state.meta_title_fb = this.magazine.metas.meta_title_fb;
        } else {
            this.state.meta_title_fb = this.magazine.title;
        }

        if(this.magazine.metas.meta_title !== "") {
            this.state.meta_title = this.magazine.metas.meta_title;
        } else {
            this.state.meta_title = this.magazine.title;
        }

        if(this.magazine.metas.meta_description_twitter !== "") {
            this.state.meta_description_twitter = this.magazine.metas.meta_description_twitter;
        } else {
            this.state.meta_description_twitter = this.magazine.metas.meta_description;
        }

        if(this.magazine.metas.meta_image_twitter !== "") {
            this.state.meta_image_twitter = this.magazine.metas.meta_image_twitter;
        } else {
            this.state.meta_image_twitter = this.magazine.wallpaper;
        }

        if(this.magazine.metas.meta_title_twitter !== "") {
            this.state.meta_title_twitter = this.magazine.metas.meta_title_twitter;
        } else {
            this.state.meta_title_twitter = this.magazine.title;
        }

        if(this.magazine.indexed !== null){
            this.state.indexed = this.magazine.indexed;
        } else {
            this.state.indexed = 1;
        }

    }

    render() {

        let env;

        if(typeof window !== "undefined"){
            env = (window.__ENVIRONMENT__) ? window.__ENVIRONMENT__ : "development";
        } else {
            env = (process.env.BACK_OFFICE) ? process.env.BACK_OFFICE : "development";
        }

        return [
            <Helmet key={ 1 }>
                <meta charSet="utf-8"/>

                <meta
                    name="apple-mobile-web-app-status-bar-style"
                    content="default"/>

                <title>{this.state.meta_title}</title>
                { (env === "development" || this.state.indexed === 0) &&
                    <meta name="robots" content="noindex, nofollow"/>
                }
                <link rel="canonical" href={ "https://" + this.magazine.slug + this.props.location.pathname }/>
                <meta name="description" content={this.state.meta_description}/>

                <meta property="og:type" content="article"/>
                <meta property="og:title" content={this.state.meta_title_fb}/>
                <meta property="og:description" content={this.state.meta_description_fb}/>
                <meta property="og:url" content={ "https://" + this.magazine.slug + this.props.location.pathname } />
                <meta property="og:image" content={this.state.meta_image_fb}/>

                <meta name="twitter:card" content="summary_large_image"/>

                <meta name="twitter:description" content={this.state.meta_description_twitter}/>
                <meta name="twitter:title" content={this.state.meta_title_twitter}/>
                <meta name="twitter:image" content={this.state.meta_image_twitter}/>

            </Helmet>,
            <Manifest key={ 2 }
                metas={ this.state } getAll={ this.props.getAll } />
        ]

    }

}

export default withApollo(withRouter(MetasWebsite));
