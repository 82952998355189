import React, { Component } from 'react';
import PropTypes from 'prop-types';
export const GlobalContext = React.createContext('magazine');

class GlobalComponent extends Component {

    getChildContext() {
        if(typeof this.props.getMagazine !== "undefined"){
            return {
                magazine: this.props.getMagazine,
                preview: this.props.isPreview,
                resetCache: (this.props.resetCache) ? this.props.resetCache : false
            };
        }
    }

    render(){
        return (
            <div>
                {this.props.children}
            </div>
        )
    }
}

GlobalComponent.childContextTypes = {
    magazine: PropTypes.object,
    preview: PropTypes.bool,
    resetCache: PropTypes.bool
};

export default GlobalComponent;



