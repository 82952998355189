import * as React from "react";
import { Component } from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";

export const ScrollContext = React.createContext('scroll');

export class ScrollProviderClass extends Component {

    constructor(props) {
        super(props);

        this.changeScrollWidth = (width, scroll) => {

            let scrollDirection;

            if(this.state.scrollValue < scroll){
                scrollDirection = "down";
                this.props.changeScrollDirection(scrollDirection);
            }

            if(this.state.scrollValue > scroll){
                scrollDirection = "up";
                this.props.changeScrollDirection(scrollDirection);
            }

            this.setState(state => ({
                scrollWidth: width,
                scrollValue: scroll,
            }));

        };

        this.resetScrollWidth = () => {
            this.setState(state => ({
                scrollWidth: 0,
                scrollValue: 0,
            }));
        };

        this.state = {
            scrollWidth: 0,
            scrollValue: 0,
            changeScrollWidth: this.changeScrollWidth,
            resetScrollWidth: this.resetScrollWidth
        };

    }


    render() {

        return(
            <ScrollContext.Provider value={ this.state }>
                { this.props.children }
            </ScrollContext.Provider>
        )

    }


}

const mapStateToProps = (state, props) => {
    return {
        scrollDirection: state.scrolled.scrollDirection
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeScrollDirection: (value) => {
            dispatch({
                type: "CHANGE_SCROLL_DIRECTION",
                scrollDirection: value
            })
        },
    };
};

ScrollProviderClass.contextTypes = {
    magazine: PropTypes.object
};

export let ScrollProvider = connect(mapStateToProps, mapDispatchToProps)(ScrollProviderClass);
