import { gql } from "apollo-client-preset";

export const GET_MAGAZINE = gql`
  query GET_MAGAZINE {
    magazine {
      slug
      title
      metas
      wallpaper
      indexed
    }
  }
`;

export const IS_PREVIEW = gql`
  query IS_PREVIEW($value: Boolean){
    isPreview(value: $value) {
      preview
    }
  }
`;

export const RESET_CACHE = gql`
  query RESET_CACHE($value: Boolean){
    resetCache(value: $value) {
      cache
    }
  }
`;

export const GET_MAGAZINE_BY_SLUG = gql`
  query GET_MAGAZINE_BY_SLUG($magazine_slug: String){
    getMagazine(slug: $magazine_slug) {
      slug
      title
      metas
      wallpaper
      indexed
    }
  }
`;

export const GET_ARTICLES = gql`
  query GET_ARTICLES($numero_id: ID!){
    articles(numero_id: $numero_id) {
        _id
        slug
        title
        couleur_titre
        metas
        author {
            first_name
            last_name
            image
            metier
            email
        }
        description
        photographe
        numero
        positionChapitre
        category {
          _id
          level
          slug
          title
          couleur_titre
          parent
          order
          description
          wallpaper {
            url
            formats {
              menu
            }
            width
            height
            preview
            legende
          }
          cuid
          status
          number
          custom
          couleur
        }
        wallpaper {
           url
           width
           height
           formats {
              menu
           }
           preview
           legende
        }
        timeForRead
        status
        cuid
        content
        custom
    },
  }
`;

export const ADD_ARTICLE_READ = gql`
    mutation addReadArticle($id: ID!) {
      addReadArticle(id: $id) @client {
        _id
        readed
      }
    }
`;

export const GET_ARTICLE_READ = gql`
    query getReadArticle($id: ID!) {
      getReadArticle(id: $id) @client {
        _id
        readed
      }
    }
`;

export const UPDATE_ARTICLE_READ = gql`
    mutation setReadArticle($article_id: ID!) {
      setReadArticle(id: $article_id) @client 
    }
`;

export const VERIFY_FORM = gql`
    query verifyForm($magazine_slug: String, $infos: JSON) {
      verifyContactForm(magazine_slug: $magazine_slug, infos: $infos) {
        status
        errors {
          name
          error
        }
        message
      }
    }
`;

export const SEND_FORM = gql`
    query sendForm($magazine_slug: String, $infos: JSON) {
      sendContactForm(magazine_slug: $magazine_slug, infos: $infos) {
        message
        errors
        status
      }
    }
`;

export const SEARCH_FORM = gql`
    query searchArticlesForm($magazine_slug: String, $input: String, $offset: Int) {
      searchArticles(magazine_slug: $magazine_slug, input: $input, offset: $offset) {
        found_posts
        articles {
            _id
            slug
            title
            couleur_titre
            author {
                first_name
                last_name
                image
                metier
                email
            }
            description
            photographe
            numero
            category {
              _id
              level
              slug
              title
              couleur_titre
              order
              number
              custom
              description
              wallpaper {
                url
                formats {
                  menu
                }
                width
                height
                preview
                legende
              }
              couleur
            }
            wallpaper {
               url
               width
               height
               preview
               legende
            }
            timeForRead
            custom
            numeroObj {
                _id
                slug
                title
            }
        }
      }
    }
`;

export const GET_ARTICLE = gql`
  query GET_ARTICLE($magazine_slug: String, $article_id: ID!, $refresh: Boolean){
    getArticle(magazine_slug: $magazine_slug, _id: $article_id, refresh: $refresh) {
        _id
        slug
        title
        couleur_titre
        author {
            first_name
            last_name
            image
            metier
            email
        }
        description
        photographe
        numero
        category {
          _id
          level
          slug
          title
          couleur_titre
          order
          number
          custom
          description
          wallpaper {
            url
            formats {
              menu
            }
            width
            height
            preview
            legende
          }
          couleur
        }
        wallpaper {
          url
          formats {
            menu
          }
          width
          height
          preview
          legende
        }
        timeForRead
        content
        custom
    },
  }
`;

export const GET_CATEGORY = gql`
  query GET_CATEGORY($magazine_slug: String, $category_id: ID!, $refresh: Boolean){
    getCategory(magazine_slug: $magazine_slug, _id: $category_id, refresh: $refresh) {
        _id
        level
        slug
        title
        couleur_titre
        timeForRead
        description
        wallpaper {
           url
           formats {
             menu
           }
           width
           height
           preview
           legende
        }
        metas
        order
        number
        custom
        couleur
        content
    },
  }
`;

export const GET_PAGE = gql`
  query GET_PAGE($magazine_slug: String, $slug: String!){
    getPage(magazine_slug: $magazine_slug, slug: $slug) {
        slug
        title
        content
        metas
        custom
    },
  }
`;

export const GET_ARTICLE_DESCRIPTION = gql`
  query GET_ARTICLE($magazine_slug: String, $article_id: ID!, $refresh: Boolean){
    getArticle(magazine_slug: $magazine_slug, _id: $article_id, refresh: $refresh) {
        description
    },
  }
`;

export const GET_ALL_ARTICLES_WITH_CONTENT = gql`
    query GET_ALL_ARTICLES_WITH_CONTENT {
        getArticles @client {
            _id
            content
            description
            title
            couleur_titre
            slug
            numero
            category {
              _id
              level
              slug
              title
              couleur_titre
              order
              number
              custom
              description
              wallpaper {
                url
                formats {
                  menu
                }
                width
                height
                preview
                legende
              }
              couleur
            }
            custom
        }
    }
`;
//
// export const GET_ALL_ARTICLES= gql`
//     query GET_ALL_ARTICLES {
//         getArticles @client {
//             content
//         }
//     }
// `;


export const GET_PREVIEW_ARTICLE = gql`
  query GET_PREVIEW_ARTICLE($magazine_slug: String, $article_id: ID!){
    getPreviewArticle(magazine_slug: $magazine_slug, _id: $article_id) {
        _id
        slug
        title
        couleur_titre
        author {
            first_name
            last_name
            image
            metier
            email
        }
        description
        photographe
        numero
        category {
          _id
          level
          slug
          title
          couleur_titre
          order
          number
          custom
          description
          wallpaper {
            url
            formats {
              menu
            }
            width
            height
            preview
            legende
          }
          couleur
        }
        wallpaper {
           url
           formats {
             menu
           }
           width
           height
           preview
           legende
        }
        timeForRead
        content
        custom
    },
  }
`;

export const GET_CATEGORIES = gql`
  query GET_CATEGORIES($numero_id: ID!){
    categories(numero_id: $numero_id) {
        _id
        level
        slug
        title
        couleur_titre
        parent
        childrens
        order
        description
        wallpaper {
          url
          formats {
            menu
          }
          width
          height
          preview
          legende
       }
        cuid
        status
        number
        custom
        couleur
    },
  }
`;

export const GET_CURRENT_NUMERO_SWITCH = gql`
    query getCurrentNumeroSwitch {
        NumeroSwitch {
            slug
        }
    }
`;

export const GET_CURRENT_NUMERO = gql`
  query getCurrentNumero {
    currentNumero {
        _id
        slug
        title
        template_cuid
        status
        couverture_kiosque
        cuid
        metas
        visibility
        wallpaper {
          url
        }
        date
        number
        document
        couverture
        categories {
            _id
            level
            slug
            title
            couleur_titre
            order
            number
            custom
            couleur
            timeForRead
            description
            wallpaper {
              url
              formats {
                menu
              }
              width
              height
              preview
              legende
            }
        }
        articles {
            _id
            slug
            title
            couleur_titre
            metas
            author {
                first_name
                last_name
                metier
            }
            category {
                _id
                level
                slug
                title
                couleur_titre
                order
                number
                custom
                couleur
                description
                wallpaper {
                  url
                  formats {
                    menu
                  }
                  width
                  height
                  preview
                  legende
                }
            }
            wallpaper {
                url
                formats {
                  menu
                }
                width
                height
                preview
                legende
            }
            positionChapitre
            timeForRead
            custom
        }
        custom
    }
  }
`;

export const GET_NUMERO_BY_SLUG = gql`
  query getNumeroBySlug($magazine_slug: String!, $slug: String!) {
    getNumeroBySlug(magazine_slug: $magazine_slug, slug: $slug) {
        _id
        slug
        title
        template_cuid
        status
        couverture_kiosque
        cuid
        visibility
        wallpaper {
          url
        }
        metas
        date
        number
        document
        couverture
        document
        categories {
            _id
            level
            slug
            title
            couleur_titre
            order
            number
            custom
            couleur
            timeForRead
            description
            wallpaper {
              url
              formats {
                menu
              }
              width
              height
              preview
              legende
            }
        }
        articles {
            _id
            slug
            title
            couleur_titre
            metas
            author {
                first_name
                last_name
                metier
            }
            category {
                _id
                level
                slug
                title
                couleur_titre
                order
                number
                custom
                couleur
                description
                wallpaper {
                  url
                  formats {
                    menu
                  }
                  width
                  height
                  preview
                  legende
                }
            }
            wallpaper {
                url
                formats {
                  menu
                }
                width
                height
                preview
                legende
            }
            positionChapitre
            timeForRead
            custom
        }
        custom
    }
  }
`;

export const GET_NUMERO_BY_ID = gql`
  query getNumeroById($_id: ID!) {
    getNumeroById(_id: $id) {
        _id
    }
  }
`;

export const GET_MENU  = gql`
  query getMenu {
    getMenu
  }
`;

export const GET_MENU_NUMEROS = gql`
query getMenuNumeros($magazine_slug: String!) {
    datas(magazine_slug: $magazine_slug, offset: 0) {
        menuNumeros {
            _id
            slug
            title
            couverture_kiosque
            timeForRead
            nbrArticles
            nbrChapitresToRead
            type {
                _id
                slug
                title
            }
        }
    }
}
`;

export const CREATE_NUMERO_ON_DATAS = gql`
  mutation CreateNumero(
      $_id: ID!
      $slug: String!
      $title: String!
      $template_cuid: String!
      $status: Boolean!
      $cuid: ID!
      $date: String!
      $number: ID!
      $couverture: [String]
      $categories: [Category]
      $articles: [Article]
  ) {
    createNumero(
      _id: $_id
      slug: $slug
      title: $title
      template_cuid: $template_cuid
      status: $status
      cuid: $cuid
      date: $date
      number: $number
      couverture: $couverture
      categories: $categories
      articles: $articles
    ) {
        _id
        slug
        title
        template_cuid
        metas
        wallpaper {
           url
        }
        status
        cuid
        date
        visibility
        number
        couverture
        document
        categories {
            _id
            level
            slug
            title
            couleur_titre
            order
            number
            custom
            couleur
            description
            wallpaper {
              url
              formats {
                menu
              }
              width
              height
              preview
              legende
            }
        }
        articles {
            _id
            slug
            metas
            author {
                first_name
                last_name
                metier
            }
            title
            couleur_titre
            category {
                _id
                level
                slug
                title
                couleur_titre
                order
                number
                custom
                couleur
                description
                wallpaper {
                  url
                  formats {
                    menu
                  }
                  width
                  height
                  preview
                  legende
                }
            }
            wallpaper {
                url
                formats {
                  menu
                }
                width
                height
                preview
                legende
            }
            positionChapitre
            timeForRead
            custom
        }
    }
  }
`;

export const GET_ALL = gql `
query getAll($magazine_slug: String!, $offset: Int) {
    datas(magazine_slug: $magazine_slug, offset: $offset) {
        title
        menuNumeros {
            _id
            slug
            title
            couverture_kiosque
            timeForRead
            nbrChapitresToRead
            nbrArticles
            type {
                _id
                slug
                title
            }
        }
        magazine {
            title
            slug
            metas
            wallpaper
        }
        settings {
            couleur
            fonts
            custom
            logo
            audience
            kiosque
            contact
            socials
            theme
            manifest
        }
        numeros {
            _id
            slug
            title
            visibility
            metas
            couverture_kiosque
            wallpaper {
                url
            }
            template_cuid
            status
            cuid
            date
            number
            document
            couverture
            custom
            categories {
                _id
                level
                slug
                title
                couleur_titre
                order
                number
                custom
                couleur
                timeForRead
                description
                wallpaper {
                  url
                  formats {
                    menu
                  }
                  width
                  height
                  preview
                  legende
                }
            }
            articles {
                _id
                slug
                author {
                    first_name
                    last_name
                    metier
                }
                title
                couleur_titre
                metas
                category {
                    _id
                    level
                    slug
                    title
                    couleur_titre
                    order
                    number
                    custom
                    couleur
                    description
                    wallpaper {
                      url
                      formats {
                        menu
                      }
                      width
                      height
                      preview
                      legende
                    }
                }
                wallpaper {
                    url
                    formats {
                      menu
                    }
                    width
                    height
                    preview
                    legende
                }
                positionChapitre
                timeForRead
                custom
            }
        }
    }
}

`;

/*

export const GET_ALL = gql `
query getAll($magazine_slug: String!, $offset: Int) {
    numeros(magazine_slug: $magazine_slug, offset: $offset) {
        _id
        slug
        title
        subtitle
        wallpaper
        template_cuid
        status
        cuid
        date
        number
        couverture
        categories {
            _id
            level
            slug
            title
            parent
            childrens
            order
            description
            wallpaper
            cuid
            status
            number
        }
        articles {
            _id
            slug
            title
            author {
                first_name
                last_name
                image
                metier
                email
            }
            description
            photographe
            category {
                _id
                level
                slug
                title
                parent
                order
                description
                wallpaper
                cuid
                status
                number
            }
            wallpaper {
                url
                formats {
                  menu
                }
                preview
                legende
            }
            positionChapitre
            timeForRead
            status
            cuid
        }
    },
}

`;

 */

export const ADD_NUMERO = gql`
    query addNumero {
        numero {
           _id
            slug
            title
            date
            visibility
            wallpaper {
                url
            }
            metas
            number
            couverture
            categories {
                _id
                level
                slug
                title
                couleur_titre
                order
                description
                wallpaper {
                  url
                  formats {
                    menu
                  }
                  width
                  height
                  preview
                  legende
                }
                number
                custom
                couleur
            }
            articles {
                _id
                slug
                title
                couleur_titre
                metas
                description
                author {
                    first_name
                    last_name
                    metier
                }
                photographe
                category {
                  _id
                  level
                  slug
                  title
                  order
                  number
                  custom
                  couleur
                  description
                  wallpaper {
                    url
                    formats {
                      menu
                    }
                    width
                    height
                    preview
                    legende
                  }
                }
                wallpaper {
                   url
                   formats {
                      menu
                   }
                   width
                   height
                   preview
                   legende
                }
                positionChapitre
                timeForRead
                content
                custom
            }
            date
            number
            couverture 
            }
    }
`;

// export const UPDATE_PREVIEW_ARTICLE = gql`
//     query updatePreviewArticle {
//
//
//     }
// `;

export const UPDATE_CURRENT_NUMERO_SWITCH = gql`
    query updateCurrentNumeroSwitch {
        NumeroSwitch {
            slug
        }
    }
`;

export const UPDATE_CURRENT_NUMERO = gql`
  mutation updateCurrentNumero @client {
    currentNumero @client {
        _id
        slug
        title
        date
        metas
        template_cuid
        status
        couverture_kiosque
        cuid
        visibility
        wallpaper {
           url
        }
        metas
        date
        number
        couverture
        visibility
        custom
        categories {
            _id
            level
            slug
            title
            couleur_titre
            order
            number
            custom
            couleur
            timeForRead
            description
            wallpaper {
              url
              formats {
                menu
              }
              width
              height
              preview
              legende
            }
        }
        articles {
            _id
            slug
            title
            couleur_titre
            description
            metas
            author {
                first_name
                last_name
                metier
            }
            photographe
            category {
              _id
              level
              slug
              title
              couleur_titre
              order
              number
              custom
              couleur
              description
              wallpaper {
                url
                formats {
                  menu
                }
                width
                height
                preview
                legende
              }
            }
            wallpaper {
               url
               formats {
                  menu
               }
               width
               height
               preview
               legende
            }
            positionChapitre
            timeForRead
            content
            custom
        }
    }
  }
`;

export const GET_HEADER = gql`
  query getHeader($magazine_slug: String!, $offset: Int){
    numeros(magazine_slug: $magazine_slug, offset: $offset) {
        _id
        slug
        title
        template_cuid
        status
        cuid
        metas
        date
        visibility
        wallpaper {
           url
        }
        number
        document
        couverture
        custom
        categories {
            _id
            level
            slug
            title
            order
            number
            custom
            couleur
            timeForRead
            description
            wallpaper {
              url
              formats {
                menu
              }
              width
              height
              preview
              legende
            }
        }
        articles {
            _id
            slug
            title
            couleur_titre
            category {
                _id
                level
                slug
                title
                couleur_titre
                order
                number
                custom
                couleur
                description
                wallpaper {
                  url
                  formats {
                    menu
                  }
                  width
                  height
                  preview
                  legende
                }
            }
            wallpaper {
                url
                formats {
                  menu
                }
                width
                height
                preview
                legende
            }
            positionChapitre
            timeForRead
            custom
        }
    }
  }
`;
