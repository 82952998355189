import React, { Component } from 'react';
import { Route } from 'react-router-dom'
import { MagazineLoader } from "../Magazine/Magazine";

//  {/*<Route path="/" component={ MagazineLoader } />*/}

class Container extends Component {
    render() {
        return (
            <MagazineLoader />
        )
    }
}

export default Container;
