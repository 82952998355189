import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'

const SET_APP_FALSE = 'SET_APP_FALSE';

const initialState = {
    app: true,
};

const app = (state = initialState, action) => {
    switch (action.type) {
        case SET_APP_FALSE:
            return {
                app: !state,
            };
        default:
            return state
    }
};

let rgpdState = {
    window: true,
    settings: {
        interaction: false,
        categories: [
            {
                title: 'Réseaux Sociaux',
                slug: 'socials',
                services: [
                    {
                        slug: 'facebook',
                        id: 1,
                        title: 'Partage Facebook',
                        desc: 'Module de partage Facebook',
                        accepted: false
                    }
                ]
            },
            {
                title: 'Mesure d\'audience',
                slug: 'audience',
                services: [
                    {
                        slug: 'google_analytics',
                        id: 2,
                        title: 'Analyse du traffic Google Analytics',
                        desc: 'Permet d\'analyser les statistiques de consultation de notre site',
                        accepted: false
                    }
                ]
            }
        ]
    }
};

const rgpd = (state = rgpdState, action) => {
    switch (action.type) {
        case 'OPEN_WINDOW_RGPD':
            return {
                ...state,
                window: true
            };
        case 'CLOSE_WINDOW_RGPD':
            return {
                ...state,
                window: false
            };
        case 'CHANGE_SETTINGS':
            return {
                window: false,
                settings: action.settings
            };
        case 'ALREADY_INTERACT':
            return {
                window: false,
                settings: {
                    ...state.settings,
                    interaction: true
                }
            };
        case 'BLOCK_SERVICE':
            const categories1 = state.settings.categories
                .map((category) => {
                    if(category.slug === action.category){
                        category.services.map((service) => {
                            if(service.slug === action.service){
                                service.accepted = false;
                            }
                            return service;
                        })
                    }
                    return category;
                });

            return {
                window: false,
                settings: {
                    ...state.settings,
                    categories: categories1
                }
            };
        case 'UNBLOCK_SERVICE':
            const categories2 = state.settings.categories
                .map((category) => {
                    if(category.slug === action.category){
                        category.services.map((service) => {
                            if(service.slug === action.service){
                                service.accepted = true;
                            }
                            return service;
                        })
                    }
                    return category;
                });

            return {
                window: false,
                settings: {
                    ...state.settings,
                    categories: categories2
                }
            };
        default:
            return state;
    }
};

//

let loadingViewState = {
    loading: false,
    loadingMain: false,
};

const loadingView = (state = loadingViewState, action) => {
    switch (action.type) {
        case 'LOADING_VIEW':
            return {
                loading: action.loading,
            };
        case 'LOADING_VIEW_MAIN':
            return {
                loadingMain: action.loading,
            };
        default:
            return state;
    }
};


//

let previewState = {
    previewLoading: true,
    previewArticle: null
};

const preview = (state = previewState, action) => {
    switch (action.type) {
        case 'SET_PREVIEW_ARTICLE':
            return {
                previewArticle: action.article,
                previewLoading: false
            };
        case 'PREVIEW_AVAILABLE':
            return {
                ...state,
                previewLoading: false,
            };
        default:
            return state;
    }
};

let currentArticleState = {
    currentArticle: null
};

const currentArticle = (state = currentArticleState, action) => {
    switch (action.type) {
        case 'CHANGE_CURRENT_ARTICLE':

            if(state.currentArticle) {
                if (state.currentArticle._id === action.currentArticle._id) {
                    return state;
                }
            }

            return {
                currentArticle: action.currentArticle
            };
        default:
            return state;
    }
};


let scrolledState = {
    scrolled: false,
    widthScroll: 0,
    scrollDirection: "up",
    fixTimelineBar: false
};

const scrolled = (state = scrolledState, action) => {

    switch (action.type) {
        case "CHANGE_SCROLL":
            return {
                ...state,
                scrolled: action.value
            };
        case "UPDATE_WIDTH_SCROLL":
            return {
                ...state,
                widthScroll: action.value
            };
        case "CHANGE_SCROLL_DIRECTION":
            return {
                ...state,
                scrollDirection: action.scrollDirection
            };
        case "FIX_TIMELINE_BAR":
            return {
                ...state,
                fixTimelineBar: true
            };
        case "UNFIX_TIMELINE_BAR":
            return {
                ...state,
                fixTimelineBar: false
            };
        default:
            return state;
    }
};

//

let viewState = {
    kind: "couverture",
    noImg: false
};

const view = (state = viewState, action) => {
    switch (action.type) {
        case "CHANGE_VIEW":
            return {
                ...state,
                kind: action.kind
            };
        case "SET_NO_IMG":
            return {
                ...state,
                noImg: action.value
            };
        default:
            return state;
    }
};



//

let userState = {
    articles: [],
    lastArticleRead: null,
    lastArticleReadAll: null,
    lastArticlesRead: [],
};

let videoState = false;

const video = (state = videoState, action) => {
    switch (action.type) {
        case "VIDEO_IN":
            return true;
        case "VIDEO_OUT":
            return false;
        default:
            return state;
    }
};

let renderState = {
    article: false,
    couverture: false
};

const renders = (state = renderState, action) => {
    switch (action.type) {
        case "RENDER_ARTICLE":
            return {
                ...state, article: true
            };
        case "RENDER_COUVERTURE":
            return {
                ...state, couverture: true
            };
        default:
            return state;
    }
};

//

let numerosState = {
    changeNumero: false
};

const numeros = (state = numerosState, action) => {
    switch (action.type) {
        case "CHANGE_NUMERO_ON":
            return {
                ...state, changeNumero: true,
            };
        case "CHANGE_NUMERO_OFF":
            return {
                ...state, changeNumero: true,
            };
        default:
            return state;
    }
};

//

let searchState = {
    isSearching: false
};

const search = (state = searchState, action) => {
    switch (action.type) {
        case "TOGGLE_SEARCH":
            return {
                ...state, isSearching: !state.isSearching
            };
        case "TOGGLE_SEARCH_VALUE":
            return {
                ...state, isSearching: action.value
            };
        default:
            return state;
    }
};

let menuNumeroState = {
    active: false
};

const menuNumero = (state = menuNumeroState, action) => {
    switch (action.type) {
        case "TOGGLE_MENU_NUMERO":
            return {
                ...state, active: !state.active
            };
        case "SET_MENU_NUMERO":
            return {
                ...state, active: action.value
            };
        default:
            return state;
    }
};


let menuState = {
    menuShow: false,
    menuInnerShow: false,
    menuInnerCategory: null
};

const menu = (state = menuState, action) => {
    switch (action.type) {
        case "TOGGLE_MENU":
            return {
                ...state, menuShow: !state.menuShow
            };
        case "TOGGLE_MENU_VALUE":
            return {
                ...state, menuShow: action.value
            };
        case "TOGGLE_MENU_INNER":
            return {
                ...state, menuInnerShow: !state.menuInnerShow
            };
        case "TOGGLE_MENU_INNER_VALUE":
            return {
                ...state, menuInnerShow: action.value, menuInnerCategory: action.category
            };
        default:
            return state;
    }
};

//

const user = (state = userState, action) => {

    let article, isArticle, articles;

    switch (action.type) {
        case "USER_SET_ARTICLE_READ":

            let numeroId = null;

            if(action.article.numero){
                numeroId = action.article.numero;
            } else {
                if(action.numero){
                    numeroId = action.numero._id;
                }
            }

            article = {
                _id: action.article._id,
                numero: {
                    _id: numeroId,
                },
                type: action.typeData,
                readed: true,
            };

            isArticle = false;

            articles = state.articles
                .map((article) => {
                    if(article._id.split("_")[0] === action.article._id.split("_")[0]){
                        isArticle = true;
                        return { ...article, readed: true }
                    }
                    return article
                });

            if(!isArticle) {
                articles = [...state.articles, article]
            }

            return {
                ...state,
                articles
            };
        case "USER_UNSET_ARTICLE_READ":

            article = {
                _id: action.article._id,
                numero: {
                    _id: action.article.numero,
                },
                type: action.typeData,
                readed: false,
            };

            isArticle = false;

            articles = state.articles
                .map((article) => {
                    if(article._id === action.article._id){
                        isArticle = true;
                        return { ...article, readed: false }
                    }
                    return article
                });

            if(!isArticle) {
                articles = [...state.articles, article]
            }

            return {
                ...state,
                articles
            };
        case "USER_SET_LAST_READ":

            return {
                ...state,
                lastArticleRead: {
                    ...action.article,
                    numero: {
                        _id: action.numero._id,
                        slug: action.numero.slug,
                    }
                }
            };

        case "USER_SET_LAST_READ_ALL":
            return {
                ...state,
                lastArticleReadAll: {
                    ...action.article,
                    numero: {
                        _id: action.numero._id,
                        slug: action.numero.slug,
                    }
                }
            };
        case "USER_SET_LAST_READ_V2":

            let readLast = null;

            let read = {
                ...action.article,
                numero: {
                    _id: action.numero._id,
                    slug: action.numero.slug,
                }
            };

            if(state.lastArticlesRead && state.lastArticlesRead.length > 0){
                readLast = state.lastArticlesRead
                    .filter((obj) =>
                        obj.numero._id !== action.numero._id
                    );
                readLast.push(read);

            } else {
                state.lastArticlesRead.push(read);
            }

            return {
                ...state,
                lastArticlesRead: (readLast && readLast.length > 0) ? readLast : state.lastArticlesRead
            };
        case "USER_SET_LAST_READ_V3":

            let readLast2 = null;

            let read2 = {
                ...action.article,
                numero: {
                    _id: action.numero._id,
                    slug: action.numero.slug,
                },
                date: new Date().getTime()
            };

            if(state.lastArticlesRead && state.lastArticlesRead.length > 0){
                readLast2 = state.lastArticlesRead
                    .filter((obj) =>
                        {
                            return obj._id !== action.article._id
                        }
                    );
                readLast2.push(read2);

            } else {
                state.lastArticlesRead.push(read2);
            }

            return {
                ...state,
                lastArticlesRead: (readLast2 && readLast2.length > 0) ? readLast2 : state.lastArticlesRead
            };
        default:
            return state;
    }
};



export default (history) => combineReducers({
    router: connectRouter(history),
    app, search, numeros, menu, view, loadingView, menuNumero,
    rgpd, preview, currentArticle, scrolled, user, video, renders
})

//
// export default combineReducers({
//     app,
// });
