import React, { Component } from 'react';
import { Helmet } from "react-helmet";

export class Manifest extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.putManifest(this.constructManifestObj());
    }

    constructManifestObj()  {

        let metas = this.props.metas;

        if(this.props.getAll) {
            if (metas && this.props.getAll.settings.manifest) {

                let manifest = this.props.getAll.settings.manifest;

                let port = (window.location.port !== "") ? ":" + window.location.port : "";

                return {
                    "name": metas.meta_title,
                    "short_name": metas.meta_title,
                    "description": metas.meta_description,
                    "start_url": window.location.protocol + "//" + window.location.hostname + port + "/",
                    // "start_url": "/",
                    "background_color": manifest.webmanifest.background_color,
                    "theme_color": manifest.webmanifest.theme_color,
                    "display": "standalone",
                    "orientation": "portrait",
                    "icons": [{
                        "src": manifest.webmanifest.icon.src,
                        "sizes": manifest.webmanifest.icon.size,
                        "type": manifest.webmanifest.icon.type
                    }]
                }
            } return null;
        } return null;
    }

    putManifest(manifest) {

        if(manifest){

            setTimeout(() => {

                const stringManifest = JSON.stringify(manifest);
                const blob = new Blob([stringManifest],
                    {
                        type: 'application/json'
                    }
                );
                const manifestURL = URL.createObjectURL(blob);

                if(document.querySelector('#manifest-placeholder') !== null){
                    document.querySelector('#manifest-placeholder').setAttribute('href', manifestURL);
                }

            }, 100);
        }
    }

    render(){

        if(this.props.getAll){
            let manifest = this.props.getAll.settings.manifest;

            return(
                <Helmet>

                    <link rel="shortcut icon" href={ manifest.favicon_ico } />
                    <link rel="icon" href={ manifest.webmanifest.icon.src } sizes="512x512" />

                    <meta name="mobile-web-app-capable" content="yes" />
                    <meta name="apple-mobile-web-app-capable" content="yes" />
                    <meta name="apple-touch-fullscreen" content="yes" />

                    <meta name="browsermode" content="application" />

                    <link rel="apple-touch-icon" sizes="180x180"
                          href={ manifest.apple_touch_icon } />

                    <link rel="icon" type="image/png" sizes="32x32"
                          href={ manifest.favicon_32 } />

                    <link rel="icon" type="image/png" sizes="16x16"
                          href={ manifest.favicon_16 } />

                    <link rel="mask-icon" href={ manifest.safari_pinned_tab.svg }
                          color={ manifest.safari_pinned_tab.color } />

                    <meta name="msapplication-TileColor" content={ manifest.msapplication_color } />
                    <meta name="theme-color" content={ manifest.theme_color } />


                    {/* 1136x640 landscape */}
                    <link
                        rel="apple-touch-startup-image"
                        media="screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
                        href={ manifest.launch.image_launch_screen_1136_640 }
                    />

                    {/* 2436x1125 landscape */}
                    <link
                        rel="apple-touch-startup-image"
                        media="screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
                        href={ manifest.launch.image_launch_screen_2436_1125 }
                    />

                    {/* 1792x828 landscape */}
                    <link
                        rel="apple-touch-startup-image"
                        media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
                        href={ manifest.launch.image_launch_screen_1792_828 }
                    />

                    {/* 828x1792 portrait */}
                    <link
                        rel="apple-touch-startup-image"
                        media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
                        href={ manifest.launch.image_launch_screen_828_1792 }
                    />

                    {/* 1334x750 landscape */}
                    <link
                        rel="apple-touch-startup-image"
                        media="screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
                        href={ manifest.launch.image_launch_screen_1334_750 }
                    />

                    {/* 1242x2688 portrait */}
                    <link
                        rel="apple-touch-startup-image"
                        media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
                        href={ manifest.launch.image_launch_screen_1242_2688 }
                    />

                    {/* 2208x1242 landscape */}
                    <link
                        rel="apple-touch-startup-image"
                        media="screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
                        href={ manifest.launch.image_launch_screen_2208_1242 }
                    />

                    {/* 1125x2436 portrait */}
                    <link
                        rel="apple-touch-startup-image"
                        media="screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
                        href={ manifest.launch.image_launch_screen_1125_2436 }
                    />

                    {/* 1242x2208 portrait */}
                    <link
                        rel="apple-touch-startup-image"
                        media="screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
                        href={ manifest.launch.image_launch_screen_1242_2208 }
                    />

                    {/* 2732x2048 landscape */}
                    <link
                        rel="apple-touch-startup-image"
                        media="screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
                        href={ manifest.launch.image_launch_screen_2732_2048 }
                    />

                    {/* 2688x1242 landscape */}
                    <link
                        rel="apple-touch-startup-image"
                        media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
                        href={ manifest.launch.image_launch_screen_2688_1242 }
                    />

                    {/* 2224x1668 landscape */}
                    <link
                        rel="apple-touch-startup-image"
                        media="screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
                        href={ manifest.launch.image_launch_screen_2224_1668 }
                    />

                    {/* 750x1334 portrait */}
                    <link
                        rel="apple-touch-startup-image"
                        media="screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
                        href={ manifest.launch.image_launch_screen_750_1134 }
                    />

                    {/* 2048x2732 portrait */}
                    <link
                        rel="apple-touch-startup-image"
                        media="screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
                        href={ manifest.launch.image_launch_screen_2048_2732 }
                    />

                    {/* 2388x1668 landscape */}
                    <link
                        rel="apple-touch-startup-image"
                        media="screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
                        href={ manifest.launch.image_launch_screen_2388_1668 }
                    />

                    {/* 1668x2224 portrait */}
                    <link
                        rel="apple-touch-startup-image"
                        media="screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
                        href={ manifest.launch.image_launch_screen_1668_2224 }
                    />

                    {/* 640x1136 portrait */}
                    <link
                        rel="apple-touch-startup-image"
                        media="screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
                        href={ manifest.launch.image_launch_screen_640_1136 }
                    />

                    {/* 1668x2388 portrait */}
                    <link
                        rel="apple-touch-startup-image"
                        media="screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
                        href={ manifest.launch.image_launch_screen_1668_2388 }
                    />

                    {/* 2048x1536 landscape */}
                    <link
                        rel="apple-touch-startup-image"
                        media="screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
                        href={ manifest.launch.image_launch_screen_2048_1536 }
                    />

                    {/* 1536x2048 portrait */}
                    <link
                        rel="apple-touch-startup-image"
                        media="screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
                        href={ manifest.launch.image_launch_screen_1536_2048 }
                    />


                </Helmet>
            );
        } return null
    }

}
