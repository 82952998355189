export default `
  scalar JSON
  
  type Cache {
    cache: Boolean
  }
  
  type Preview {
    preview: Boolean
  }
  
  type Social {
    url: String
    title: String
    icon: String
  }
  
  type Settings {
    couleur: String
    fonts: JSON
    logo: JSON
    contact: String
    audience: JSON
    kiosque: JSON
    manifest: JSON
    socials: JSON
    custom: JSON
  }
  
  type menuNumero {
    _id: ID
    slug: String
    title: String
    type: TypePublication
    nbrArticles: Int
    nbrChapitresToRead: Int
    couverture_kiosque: String
    timeForRead: String
  }
  
  type Datas {
    title: String
    menuNumeros: [menuNumero]
    magazine: MagazineWP
    numeros: [Numero]
    settings: Settings
  }
  
  type NumeroSwitch {
    slug: String
  }
  
  type Page {
    title: String
    slug: String
    content: JSON
    metas: JSON
    custom: JSON
  }
  
  type FormError {
    name: String
    error: String
  }
  
  type ContactFormResponse {
    status: String
    errors: [FormError]
    message: String
  }
  
  type SendContactFormResponse {
    message: String
    errors: JSON
    status: Boolean
  }
  
  type SearchArticlesResponse {
    articles: [ArticleSearchDetail]
    found_posts: Int
  }

  type Query {
    getArticle(magazine_slug: String, _id: ID, refresh: Boolean): ArticleDetails
    getCategory(magazine_slug: String, _id: ID, refresh: Boolean): CategoryDetail
    datas(magazine_slug: String, offset: Int): Datas
    menuNumeros: [menuNumero]
    getSettings: Settings
    verifyContactForm(magazine_slug: String, infos: JSON): ContactFormResponse
    sendContactForm(magazine_slug: String, infos: JSON): SendContactFormResponse
    searchArticles(magazine_slug: String, input: String, offset: Int): SearchArticlesResponse
    getArticles: [ArticleDetails]
    getPage(magazine_slug: String, slug: String): Page
    getPreviewArticle(magazine_slug: String, _id: ID): ArticleDetails
    getNumeroBySlug(magazine_slug: String, slug: String): Numero
    articles(numero_id: ID): [Article]
    numeros: [Numero]
    addNumero: [Numero]
    getMenu: Boolean
    updateCurrentNumeroSwitch: NumeroSwitch
    getCurrentNumeroSwitch: NumeroSwitch
    getMagazine(slug: String, offset: Int): MagazineWP
    categories(numero_id: ID): [Category]
    getNumero(_id: ID): Numero
    magazine: Magazine
    currentNumero: CurrentNumero
    getNumeroById(_id: ID): Numero
    resetCache(value: Boolean): Cache
    isPreview(value: Boolean): Preview
  }
  
  type CurrentNumero {
    _id: ID
    slug: String
    metas: JSON
    title: String
    subtitle: String
    wallpaper: Wallpaper
    template_cuid: String
    status: String
    cuid: String
    couverture: String
  }
  
  type Numero {
    _id: ID
    slug: String
    metas: JSON
    title: String
    subtitle: String
    wallpaper: Wallpaper
    visibility: Boolean
    template_cuid: String
    status: String
    articles: [Article]
    categories: [Category]
    couverture_kiosque: String
    cuid: String
    date: String
    number: Int
    document: String
    couverture: [String]
    custom: JSON
  }
  
  type MagazineWP {
    title: String
    slug: String
    metas: JSON
    wallpaper: String
    resetPersistCache: Boolean
    indexed: Int
  }
  
  type Magazine {
    _id: ID
    slug: String
    titre: String
    slogan: String
    cuid: String
    dateAdded: String
    numeros: [Numero]
  }
  
  type Attributes {
    title: [String]
    color: String
  }
  
  type AttributesCategory {
    color: String
  }
  
  type Category {
    _id: ID
    level: Int
    slug: String
    title: String
    couleur_titre: String
    position: Int
    parent: ID
    childrens: [ID]
    order: Int
    description: String
    cuid: String
    timeForRead: String
    attributes: AttributesCategory
    status: String
    number: Int
    custom: JSON
    wallpaper: Wallpaper
    couleur: String
  }
  
  type ArticleRead {
    _id: ID
    readed: Boolean
  }
  
  type Formats {
    menu: String
  }
  
  type Wallpaper {
    url: String
    width: Int
    height: Int
    formats: Formats
    preview: String
    legende: String
  }
  
  type Citation {
    kind: String
    citation: String
    author: String
  }
  
  type Text {
    kind: String
    text: String
    styled: Boolean
  }
  
  type Title {
    kind: String
    level: Int
    text: String
  }
  
  type Image {
    url: String
  }
  
  type Slider {
    kind: String
    images: [ Image ]
  }
  
  type Images {
    kind: String
    images: [ Image ]
  }
  
  type Article {
    _id: ID
    slug: String
    title: String
    couleur_titre: String
    metas: JSON
    positionChapitre: Int
    description: String
    numero: ID
    isRead: Boolean
    category: Category
    attributes: Attributes
    timeForRead: Int
    status: String
    wallpaper: Wallpaper
    content: JSON
    isRead: Boolean
    custom: JSON
  }
  
  type ArticleSearchDetail {
    _id: ID
    slug: String
    title: String
    couleur_titre: String
    positionChapitre: Int
    description: String
    photographe: String
    numero: ID
    category: Category
    attributes: Attributes
    timeForRead: Int
    status: String
    cuid: String
    wallpaper: Wallpaper
    content: JSON
    custom: JSON
    numeroObj: menuNumero
  }
  
  type ArticleDetails {
    _id: ID
    slug: String
    title: String
    couleur_titre: String
    positionChapitre: Int
    description: String
    photographe: String
    numero: ID
    category: Category
    attributes: Attributes
    timeForRead: Int
    status: String
    cuid: String
    wallpaper: Wallpaper
    content: JSON
    custom: JSON
  }
  
  type TypePublication {
    _id: ID
    slug: String
    title: String
  }
  
  type CategoryDetail {
    _id: ID
    level: Int
    slug: String
    title: String
    couleur_titre: String
    timeForRead: Int
    parent: ID
    childrens: [ID]
    order: Int
    description: String
    wallpaper: Wallpaper
    attributes: AttributesCategory
    status: String
    metas: JSON
    number: Int
    custom: JSON
    couleur: String
    content: JSON
  }
  
  type Mutation {
    createNumero (_id: ID!,
      slug: String!,
      title: String!,
      template_cuid: String!,
      status: Boolean!,
      cuid: ID!,
      date: String!,
      number: ID!,
      couverture: [String],
      categories: [Category],
      articles: [Article]) : Numero
  }
  
`;
