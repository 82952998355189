import React, { Component } from 'react';
import { GET_ALL } from "../../../Queries";
import { Query } from "react-apollo";
import PropTypes from "prop-types";
import { MagazineSettings, SettingsContext } from "../MagazineSettings";
import Themes from "../../Themes/Themes";
import { ServiceCookiesComponent } from "../../ServiceCookies.js/ServiceCookiesComponent";
// import WebFont from "webfontloader";

//import styles from "../style/futur.module.css";

const Header = () => {
    return (
        <div className="header">
            HEADER MAGAZINE
        </div>
    )
};

const Footer = () => {
    return (
        <div className="footer">
            FOOTER MAGAZINE
        </div>
    )
};

export default class MagazineView extends Component {

    constructor(props) {
        super(props);
    }

    render() { let that = this;

        return (
            <Query query={ GET_ALL } variables={{
                magazine_slug: this.context.magazine.slug,
                offset: 0
            }}>
                {({loading, error, data, updateQuery, client, networkStatus}) => {

                    if (loading) return null;
                    if (error) return `Error! ${error.message}`;

                    return(
                        <MagazineViewInner settings={ data.datas.settings }/>
                    )

                }}
            </Query>
        )


    }


}

MagazineView.contextTypes = {
    magazine: PropTypes.object
};


class MagazineViewInner extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        if(!this.props.settings) {
            return null
        }

        return(
            <MagazineSettings settings={ this.props.settings }>

                <SettingsContext.Consumer>
                    {({ fonts }) => (
                        <div className="magazine" style={ fonts.family2 }>
                            <div>
                                <ServiceCookiesComponent>
                                    <Themes theme={ this.props.settings.theme } fonts={ fonts }/>
                                </ServiceCookiesComponent>
                            </div>
                        </div>
                    )}
                </SettingsContext.Consumer>

            </MagazineSettings>
        )
    }

}
