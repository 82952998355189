import ReactGA from 'react-ga';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { ServiceCookie } from "./ServiceCookie";

import { SettingsContext } from "../Magazine/MagazineSettings";

class GoogleAnalytics extends Component {

    constructor(props){
        super(props);

        this.state = {
            rgpd: null,
            change: false,
            init: true,
            UAid: null
        };

        this.serviceCookie = new ServiceCookie();

    }

    componentDidMount() {
        this.setState({
            rgpd: this.props.rgpd
        })
    }

    componentDidUpdate(prevProps, prevState) {

        let cookie = new ServiceCookie();

        if(this.props.audience.google.UA){
          if(!this.state.UAid) {
            this.setState({
              UAid: this.props.audience.google.UA
            });
          }
        }

        if (typeof window !== "undefined") {
            if(this.state.UAid)
                this.initGA(this.props.audience);
        }

        if(prevState.rgpd){

            let prev = cookie.findServiceAcceptation(prevState.rgpd, 'audience', 'google_analytics');
            let next = cookie.findServiceAcceptation(this.props.rgpd, 'audience', 'google_analytics');

            if(next !== prev){

                let rgpd = JSON.parse(JSON.stringify( this.props.rgpd ));

                this.setState({
                    rgpd: rgpd,
                    change: true
                });

            } else {
                if(this.state.change){
                    this.setState({
                        change: false
                    });
                }
            }

        }


    }

    initCookie(audience) {

        if(this.state.init){

            if (this.serviceCookie.isActivated('google_analytics') === false) {
                // this.disableGA();
                this.activateGA(false);
                this.props.disableGoogleAnalytics();
                this.props.alreadyInteractRGPD();

            } else if(this.serviceCookie.isActivated('google_analytics') === true){
                this.activateGA();
                this.props.activateGoogleAnalytics();
                this.props.alreadyInteractRGPD();

            }
            this.setState({
                init: false
            })
        }
    }


    activateGA(activate = true) {

        window[`ga-disable-${ this.state.UAid }`] = !activate;

        if(activate) {

            ReactGA.initialize(this.state.UAid);

            ReactGA.pageview(window.location.pathname + window.location.search);

            if (this.serviceCookie.set('google_analytics')) {
            } else {
                console.log("Cookies non activés");
            }

        } else {

            window.ga = null;

            import("./psl.min.js").then((psl) => {
                let parsed = psl.default.parse(window.location.hostname);
                this.serviceCookie.remove("_ga", "/", "." + parsed.domain);
                this.serviceCookie.remove("ga", "/", "." + parsed.domain);
                this.serviceCookie.remove("_gat", "/", "." + parsed.domain);
                this.serviceCookie.remove("gat", "/", "." + parsed.domain);
                this.serviceCookie.remove("_gid", "/", "." + parsed.domain);
                this.serviceCookie.remove("gid", "/", "." + parsed.domain);
            });
        }

    }

    disableGA() {
        if(this.serviceCookie.disable('google_analytics')){
        } else {
            console.log("Cookies non activés");
        }
    }

    initGA() {

        if(this.state.rgpd && this.state.change) {

            if (this.serviceCookie.isAccepted(this.state.rgpd, 'audience', 'google_analytics')) {
                this.activateGA();
            } else {
                this.activateGA(false);
                this.disableGA();
            }

            this.props.alreadyInteractRGPD();

        }

        if(this.state.rgpd) {
            this.initCookie();
        }

    }

    render(){
        return this.props.children;
    }

}

const mapStateToProps = (state, props) => {

    return {
        rgpd: state.rgpd
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        activateGoogleAnalytics: () => {
            return dispatch({
                type: 'UNBLOCK_SERVICE',
                category: 'audience',
                service: 'google_analytics'
            })
        },
        disableGoogleAnalytics: () => {
            return dispatch({
                type: 'BLOCK_SERVICE',
                category: 'audience',
                service: 'google_analytics'
            })
        },
        alreadyInteractRGPD: () => {
            return dispatch({
                type: 'ALREADY_INTERACT'
            })
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleAnalytics);
