import React, { Component } from 'react';

export class RGPDPortals extends Component {

    render(){
        return(
            <div id="rgpd-portal" />
        )

    }

}
