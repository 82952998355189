import React, { Component } from 'react';

import Container from "./modules/Container/Container";
import { Route, Router, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

/*<Route path="/" component={ Container } />*/

class App extends Component {

  render() {

    return (
        <Route path="/" render={ props => (
            <Container {...props}  />
        )}/>
    );

  }
}


const mapStateToProps = (state, props) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
