import React, { Component } from 'react';
import Loadable from 'react-loadable';
import {GET_ALL} from "../../Queries";
import {Query} from "react-apollo";
import PropTypes from "prop-types";

const loadingComponent = () => {
    return (
        <div className="loading-component" />
    )
};

const StandardThemeComponent = Loadable({
    loader: () => import(/* webpackChunkName: "StandardThemeComponent" */ './standard/Index'),
    loading: loadingComponent,
    modules: ['StandardThemeComponent']
});

const PoldThemeComponent = Loadable({
    loader: () => import(/* webpackChunkName: "PoldThemeComponent" */ './pold/Index'),
    loading: loadingComponent,
    modules: ['PoldThemeComponent']
});

const GeneriaThemeComponent = Loadable({
    loader: () => import(/* webpackChunkName: "GeneriaThemeComponent" */ './generia/Index'),
    loading: loadingComponent,
    modules: ['GeneriaThemeComponent']
});

const CCIThemeComponent = Loadable({
    loader: () => import(/* webpackChunkName: "CCIThemeComponent" */ './cci/Index'),
    loading: loadingComponent,
    modules: ['CCIThemeComponent']
});

const AgircThemeComponent = Loadable({
    loader: () => import(/* webpackChunkName: "AgircThemeComponent" */ './agirc/Index'),
    loading: loadingComponent,
    modules: ['AgircThemeComponent']
});

const AdemeThemeComponent = Loadable({
    loader: () => import(/* webpackChunkName: "AdemeThemeComponent" */ './ademe/Index'),
    loading: loadingComponent,
    modules: ['AdemeThemeComponent']
});

const HerThemeComponent = Loadable({
    loader: () => import(/* webpackChunkName: "HerThemeComponent" */ './her/Index'),
    loading: () => {  return null },
    modules: ['HerThemeComponent']
});

const HerV2ThemeComponent = Loadable({
    loader: () => import(/* webpackChunkName: "HerV2ThemeComponent" */ './her2020/Index'),
    loading: () => {  return null },
    modules: ['HerV2ThemeComponent']
});

const ComEntThemeComponent = Loadable({
    loader: () => import(/* webpackChunkName: "ComEntThemeComponent" */ './com-ent/Index'),
    loading: () => {  return null },
    modules: ['ComEntThemeComponent']
});

const CroixRougeThemeComponent = Loadable({
    loader: () => import(/* webpackChunkName: "CroixRougeThemeComponent" */ './croix-rouge/Index'),
    loading: () => {  return null },
    modules: ['CroixRougeThemeComponent']
});

const CroixRougeV2ThemeComponent = Loadable({
    loader: () => import(/* webpackChunkName: "CroixRougeV2ThemeComponent" */ './croix-rouge-v2/Index'),
    loading: () => {  return null },
    modules: ['CroixRougeV2ThemeComponent']
});

const ParisHabitatThemeComponent = Loadable({
    loader: () => import(/* webpackChunkName: "ParisHabitatThemeComponent" */ './paris-habitat/Index'),
    loading: loadingComponent,
    modules: ['ParisHabitatThemeComponent']
});

const CHUNiceThemeComponent = Loadable({
  loader: () => import(/* webpackChunkName: "CHUNiceThemeComponent" */ './chu-nice/Index'),
  loading: loadingComponent,
  modules: ['CHUNiceThemeComponent']
});

const KlesiaThemeComponent = Loadable({
    loader: () => import(/* webpackChunkName: "KlesiaThemeComponent" */ './klesia/Index'),
    loading: loadingComponent,
    modules: ['KlesiaThemeComponent']
});


class Themes extends Component {

    constructor(props) {
        super(props)
    }

    switch(datas) {
        const { theme } = this.props;

        switch(theme) {
            case 'standard':
                return <StandardThemeComponent />;
            case 'ademe':
                return <AdemeThemeComponent />;
            case 'croix-rouge':
                return <CroixRougeThemeComponent />;
            case 'croix-rouge-v2':
                return <CroixRougeV2ThemeComponent datas={ datas } />;
            case 'com-ent':
                return <ComEntThemeComponent/>;
            case 'her':
                return <HerThemeComponent/>;
            case 'her2020':
                return <HerV2ThemeComponent/>;
            case 'pold':
                return <PoldThemeComponent />;
            case 'generia':
                return <GeneriaThemeComponent />;
            case 'cci':
                return <CCIThemeComponent datas={ datas }/>;
            case 'agirc':
                return <AgircThemeComponent datas={ datas }/>;
            case 'paris-habitat':
                return <ParisHabitatThemeComponent datas={ datas }/>;
            case 'chu-nice':
                return <CHUNiceThemeComponent datas={ datas }/>;
            case 'klesia':
                return <KlesiaThemeComponent datas={ datas }/>;
            default:
                return <div>Theme not found.</div>;
        }

    }

    render() {
        return(
            <Query query={ GET_ALL } variables={{
                magazine_slug: this.context.magazine.slug,
                offset: 0
            }}>
                {({loading, error, data, updateQuery, client, networkStatus}) => {
                    if(!data.datas) return null;
                    return this.switch(data.datas);
                }}
            </Query>
        )
    }

}

export default Themes;

Themes.contextTypes = {
    magazine: PropTypes.object
};
