import { createStore, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createBrowserHistory, createMemoryHistory } from 'history';
import rootReducer from './reducers';
import { events } from "./middlewares/events";
import reduxLogger from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import storage from 'redux-persist/lib/storage'

// A nice helper to tell us if we're on the server
export const isServer = !(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
);

export default (url = '/') => {
    // Create a history depending on the environment
    const history = isServer
        ? createMemoryHistory({
            initialEntries: [url]
        })
        : createBrowserHistory();

    const enhancers = [];

    // Dev tools are helpful
    if (process.env.NODE_ENV === 'development' && !isServer) {
        const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

        if (typeof devToolsExtension === 'function') {
            enhancers.push(devToolsExtension());
        }
    }

    const middleware = [events, thunk, routerMiddleware(history)];
    // const middleware = [events, thunk, routerMiddleware(history), reduxLogger];

    const composedEnhancers = compose(
        applyMiddleware(...middleware),
        ...enhancers
    );

    // Do we have preloaded state available? Great, save it.
    const initialState = !isServer ? window.__REDUX_STATE__ : {};

    // Delete it once we have it stored in a variable
    if (!isServer) {
        delete window.__REDUX_STATE__;
    }

    const persistConfig = {
        key: 'user',
        whitelist: ['user'],
        storage,
        timeout: 0,
    };

    const persistedReducer = connectRouter(history)(persistReducer(persistConfig, rootReducer(history)));

    // Create the store
    const store = createStore(
        persistedReducer,
        initialState,
        composedEnhancers
    );

    return {
        store,
        persistor: persistStore(store),
        history
    };
};
