

import React, { Component } from 'react';
import { connect } from 'react-redux';
import MagazineView from "./views/MagazineView";
import { Route } from "react-router-dom";
import {GET_ALL, GET_MAGAZINE_BY_SLUG, GET_MENU, IS_PREVIEW} from "../../Queries";
import { Query } from "react-apollo";
import "./style/magazine.scss";
import PropTypes from 'prop-types';
import { MagazineContext } from "./MagazineContext";
import MetasWebsite from "../Metas/MetasWebsite";
import { Manifest } from "../Manifest/Manifest";
import Prefetch from "../Prefetch/Prefetch";
import {RGPDPortals} from "../RGPD/RGPDPortals";

class MagazineCtx extends Component {

    getChildContext() {
        return {
            magazine: this.props.magazine,
        }
    }

    render() {
        return(
            <div>
                {this.props.children}
            </div>
        )
    }


}

MagazineCtx.childContextTypes = {
    magazine: PropTypes.object
};

export class MagazineLoader extends Component {

    render() {

        return(
            <div>
                <Magazine magazine={ this.context.magazine } preview={ this.context.preview }/>
            </div>
        )
    }

}

MagazineLoader.contextTypes = {
    magazine: PropTypes.object,
    preview: PropTypes.bool
};

class Magazine extends Component {

    constructor(props){
        super(props);
    }

    isPreview(){

        if(typeof this.props.preview !== "undefined"){
            return this.props.preview;
        } else {
            if (typeof window !== "undefined") {
                let parameters = window.location.search.substr(1);
                return parameters.includes("preview=true");
            } else {
                return false;
            }

        }

    }

    isResetCache() {

        if(typeof this.props.magazine === "undefined") {
            if (typeof window !== "undefined") {
                let parameters = window.location.search.substr(1);

                let cacheParam = parameters.includes("cache");

                if(!cacheParam) {
                    return false;
                } else {
                    let param = parameters.includes("cache=true");
                    return !param;
                }

            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    getSubdomainMagazine(){

        let slug = "";

        if(typeof this.props.magazine !== "undefined"){
            slug = this.props.magazine.slug;
        } else {
            if(typeof window !== "undefined"){

                let full = window.location.host;
                slug = full;

            }
        }

        if(slug === "" || slug === "ridmi"){
            window.location.replace("http://www.google.com");
            return;
        }

        return slug;
    }

    render() {
        return (
            <Query query={ IS_PREVIEW } variables={ {
                value: this.isPreview(),
            } }>
                {({ loading, error, data, networkStatus }) => {

                    if (loading) return null;
                    let resetCache = this.isResetCache();

                    return (
                        <Query query={ GET_MAGAZINE_BY_SLUG } variables={ {
                            magazine_slug: this.getSubdomainMagazine()
                        } }>
                            {({ loading, error, data }) => {

                                if (loading) return null;
                                if (error) return `Error! ${error.message}`;

                                let magazine = data.getMagazine;

                                return(
                                    <Query query={ GET_ALL } variables={{
                                        magazine_slug: this.getSubdomainMagazine(),
                                        offset: 0
                                    }}>
                                        {({loading, error, data, updateQuery, client, networkStatus}) => {

                                            if(loading) return null;

                                            let getAll = data.datas;

                                            return (
                                                <Query query={ GET_MENU }>
                                                    {({data, client}) => {

                                                        let style = {};

                                                        if (data.menu)
                                                            style = { overflow: "hidden"};

                                                        else style = {};

                                                        return(
                                                            <div>
                                                                <Prefetch getAll={ getAll } magazine={ magazine } />
                                                                <MagazineCtx magazine={ magazine }>
                                                                    <MagazineContext.Provider value={ magazine }>
                                                                        <div className="magazine-outer" style={style}>
                                                                            <MetasWebsite magazine={ magazine }
                                                                                          getAll={ getAll }/>

                                                                            <Route path="/" component={ MagazineView } />
                                                                            <RGPDPortals />
                                                                            <div id="absolute-menu-inner"/>
                                                                            <div id="ie-support-outer" />
                                                                        </div>
                                                                    </MagazineContext.Provider>
                                                                </MagazineCtx>
                                                            </div>
                                                        )
                                                    }}
                                                </Query>
                                            )
                                        }}
                                    </Query>
                                );

                            }}
                        </Query>
                    )

                }}
            </Query>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Magazine);
