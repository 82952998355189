import { CachePersistor } from "apollo-cache-persist";

class persist {

    constructor() {
        this.persistor = null;
    }

    init(cache, storage, debug){
        this.persistor = new CachePersistor(
            {
                cache,
                storage,
                debug
            }
        )
    }

    persist() {
        this.persistor.persist().then(() => {
            return true
        });
    }

    restore() {
        this.persistor.restore().then(() => {
            return true
        });
    }

    purge() {
        if(this.persistor)
            this.persistor.purge().then(() => {
                return true;
            });
    }

}

export default new persist();
