import { LOCATION_CHANGE } from "react-router-redux";
import ReactGA from 'react-ga';
import { ServiceCookie } from "../modules/ServiceCookies.js/ServiceCookie";

function emitPageView(action) {
    setTimeout(function(){
        ReactGA.pageview(action.payload.location.pathname);
    }, 100);
}

function emitEventView(params){

    ReactGA.event({
        category: params.category,
        action: params.action,
        value: (params.value) ? params.value : 0
    });
}

export const events = store => next => action => {

    let state = store.getState();
    let serviceCookie = new ServiceCookie();
    let ga = serviceCookie.isAccepted(state.rgpd, 'audience', 'google_analytics');

    if(ga){

        switch(action.type){
            case LOCATION_CHANGE:
                emitPageView(action);
                break;
            case 'COMMENCER_LECTURE':
                emitEventView({
                    category: 'Couverture',
                    action: 'Click Commencer la lecture'
                });
                break;
            case 'REPRENDRE_LECTURE':
                emitEventView({
                    category: 'Couverture',
                    action: 'Click Reprendre la lecture'
                });
                break;
            case 'CLICK_LECTURE_UNE':
                emitEventView({
                    category: 'Couverture',
                    action: 'Click Article à la une'
                });
                break;
            case 'CLICK_ENTREE_COUV':
                emitEventView({
                    category: 'Couverture',
                    action: 'Click entrée à la une',
                    value: action.number
                });
                break;
            case 'CLICK_MENU':
                emitEventView({
                    category: 'Navigation',
                    action: 'Click Menu',
                    value: action.value
                });
            break;
            case 'CLICK_LOGO':
                emitEventView({
                    category: 'Navigation',
                    action: 'Click Logo'
                });
                break;
            case 'CLICK_TIMELINE_NEXT':
                emitEventView({
                    category: 'Timeline',
                    action: 'Click Article Suivant'
                });
                break;
            case 'CLICK_TIMELINE_PREV':
                emitEventView({
                    category: 'Timeline',
                    action: 'Click Article Précédent'
                });
                break;
            case 'CLICK_TIMELINE_ARTICLE':
                emitEventView({
                    category: 'Timeline',
                    action: 'Click Article',
                    value: action.title
                });
                break;
            case 'CLICK_ARTICLE_SOCIAL_SHARE':
                emitEventView({
                    category: 'Article',
                    action: 'Click Article Social Share',
                    value: action.kind
                });
                break;
            case 'CLICK_CONTACT':
                emitEventView({
                    category: 'Contact',
                    action: 'Click Contact Menu',
                });
                break;
            default:
                break;
        }

    }

    next(action);
};
