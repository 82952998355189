import Cookies from "js-cookie";

export class ServiceCookie {

    constructor() {
    }

    get(serviceName) {

        if(!this.isCookiesEnabled()) return false;

        if(typeof Cookies.get(serviceName) === "undefined"){
            return null
        } else {
            return Cookies.get(serviceName);
        }

    }

    set(serviceName){

        if(!this.isCookiesEnabled()) return false;

        Cookies.set(serviceName, 1, {
            expires: 31557600
        });

        return true;

    }

    disable(serviceName){
        if(!this.isCookiesEnabled()) return false;

        Cookies.set(serviceName, 0, {
            expires: 31557600
        });

        return true;

    }

    remove(serviceName, path = '/', domain = ''){
        if(!this.isCookiesEnabled()) return false;

        Cookies.remove(serviceName, { path: path, domain: domain });
        //Cookies.remove('', { path: '', domain: '.facebook.com' });

        return true;
    }

    isCookiesEnabled(){
        return true;
    }

    isActivated(serviceName) {
        if(!this.isCookiesEnabled()) return false;

        if(this.get(serviceName) == 1){
            return true
        } else if (this.get(serviceName) == 0){
            return false
        }
        return null;

    }

    findServiceAcceptation(state, categorySlug, serviceSlug){

        let acceptation = false;

        state.settings.categories.forEach((category) => {
            if(category.slug === categorySlug){
                category.services.forEach((service) => {
                    if(service.slug === serviceSlug){
                        acceptation = service.accepted;
                    }
                })
            }
        });

        return acceptation;

    }

    isAccepted(state, categorySlug, serviceSlug) {

        let accepted = false;

        state.settings.categories.forEach((category) => {
            if(category.slug === categorySlug){
                category.services.forEach((service) => {
                    if(service.slug === serviceSlug){
                        accepted = service.accepted;
                    }
                })
            }
        });

        return accepted;

    }

}
